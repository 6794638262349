import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/layout'
import styled from 'styled-components'
import { Content } from '../../components/styled'
import { AboutQuery } from '../../../graphql-types'

import { DEFAULT_SIZE, GRAY, MOBILE_BREAKPOINT } from '../../Utils'
import MarkdownRenderer from '../../components/MarkdownRenderer'

const about = ({
  location,
  data,
}: {
  location: Location
  data: AboutQuery
}) => {
  return (
    <About>
      <Layout location={location}>
        <Content>
          <div className="sidenav mobile-hide">
            <div className="logo-width"></div>
          </div>
          {/* <About>{data.allContentfulAbout.edges[0].node.text.text}</About> */}
          <div>
            <MarkdownRenderer
              className="about-text"
              html={
                data.allContentfulAbout.edges[0].node.text.childMarkdownRemark
                  .html
              }
            />
          </div>
        </Content>
      </Layout>
    </About>
  )
}

export default about

export const About = styled.div`
  /* font-size: ${DEFAULT_SIZE}rem; */

  a {
    text-decoration: underline;
  }

  .logo-width {
    width: 16rem;
    padding-right: 2rem;
    text-align: right;
  }

  .sidenav a {
    text-decoration: none;
  }

  .about-text *:first-of-type {
    margin-top: 0;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .mobile-hide {
      display: none;
    }
  }
`

export const pageQuery = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAbout {
      edges {
        node {
          text {
            text
            childMarkdownRemark {
              html
              htmlAst
            }
          }
          schemes {
            fluid(maxWidth: 1600, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
